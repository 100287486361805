import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../Components/header";
import {
  getCurrentUser,
  getUserSubscription,
} from "../../Redux/authenticate/authenticate.action";
import "./DashBoard.css";

const DashboardHome = () => {
  const { user } = useSelector((state) => state.authenticate);
  const dispatch = useDispatch();
  useEffect(() => {
    if (user) {
      dispatch(getUserSubscription());
      dispatch(getCurrentUser());
    }
  }, []);
  console.log(user);
  return (
    <div
      style={{
        backgroundImage: `url(${"/images/interview-banner.png"})`,
        backgroundSize: "cover",
        height: "100vh",
        position: "relative",
      }}
    >
      <Header />
      <div className="ic-interviews overflow-y-scroll h-screen">
        <div className="container ">
          <div className="ic-interview-container">
            <div className="dashboard-home-text text-center">
              <h1 className="text-2xl md:text-3xl lg:text-4xl font-bold mt-6">
                Welcome to Nexa, Where All Your Career Goals Become Reality
              </h1>
            </div>

            <div className="w-full flex justify-center">
              <div
                className="relative w-full sm:w-3/4 md:w-2/3 lg:w-3/4 xl:w-2/3 mt-20 xl:mt-12"
                style={{ paddingBottom: "56.25%" }} // 16:9 aspect ratio
              >
                <iframe
                  src="https://www.youtube.com/embed/YjRQ3QbykNA"
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  className="absolute top-0 left-0 w-full h-full rounded-lg"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*} <TaskList /> */}
    </div>
  );
};

export default DashboardHome;
