import { useState, useEffect, useRef } from "react";
import Header from "../../Components/header";
import MeetScreen from "./Components/MeetScreen";
import { stopMachineSpeaks } from "./Components/MachineSpeaks";
import { useDispatch, useSelector } from "react-redux";
import { getResume } from "../../Redux/ResumeBuilderSlice";
import {
  getPromptById,
  simulatorProBegin,
  validateSessionId,
} from "../../Redux/SimulatorProSlice";
import { error } from "../../Utilities/toast";
import { useSpeechRecognition } from "react-speech-recognition";
import SpeechRecognition from "react-speech-recognition";
import { FaRegFilePdf } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import chevronDoubleRightIcon from "../../assets/icons/chevronDoubleRight.svg";
import { Select } from "antd";
const { Option } = Select;

const SimulatorPro = ({ sessionsId }) => {
  const canvasRef = useRef(null);
  const fileInput = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  ///// screen recorder
  const mediaRecorderRef = useRef(null);
  const recordedChunks = useRef([]);
  const streamRef = useRef(null);
  const { listening } = useSpeechRecognition();
  const { allResume } = useSelector((state) => state.ResumeBuilder);
  const { sessionId, sessionValidation, prompt } = useSelector(
    (state) => state.SimulatorProSession
  );
  const [jobDescription, setJobDescription] = useState("");
  const [videoStream, setVideoStream] = useState(null);
  const [audioStream, setAudioStream] = useState(null);
  const [startMeet, setStartMeet] = useState(false);
  const [muted, setMuted] = useState(undefined);
  const [selectedResumeID, setSelectedResumeID] = useState("");
  const [contactDetails, setContactDetails] = useState("");
  const [activeTab, setActiveTab] = useState("upload_resume");
  const [step, setStep] = useState(1);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [interviewTone, setInterviewTone] = useState("");
  const [allowRecording, setAllowRecording] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);

  const Tones = [
    { label: "General", value: "general" },
    { label: "Screening call", value: "screening call" },
    { label: "First round interview", value: "first round interview" },
    { label: "Technical round interview", value: "technical round interview" },
    {
      label: "Final round interview with stakeholders",
      value: "final round interview with stakeholders",
    },
  ];
  const handleTabClick = (tab) => {
    setSelectedResumeID("");
    setUploadedFile(null);
    setActiveTab(tab);
  };
  const getFile = (e) => {
    const file = e.target.files[0];
    if (file.type === "application/pdf") {
      if (!file) return;
      const fileName =
        file.name.length >= 50
          ? file.name.substring(0, 51) + "..." + file.name.split(".")[1]
          : file.name;

      if (file && file.size > 2.5 * 1024 * 1024) {
        error("File size exceeds 2.5MB, please select a smaller file.");
        return;
      }

      setUploadedFile(file);
    } else {
      error("Only PDF file Supported");
    }
  };
  const handleEndCall = () => {
    navigate("/home");
    stopRecording();
    if (videoStream) {
      const videoTracks = videoStream.getTracks();
      videoTracks.forEach((track) => track.stop());
      setVideoStream(null);
    }
    if (audioStream) {
      const audioTracks = audioStream.getTracks();
      audioTracks.forEach((track) => track.stop());
      setAudioStream(null);
      setMuted(undefined);
    }
    setSelectedResumeID("");
    setJobDescription("");
    setStartMeet(false);
    stopMachineSpeaks();
  };
  const requestVideo = () => {
    if (navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices
        .getUserMedia({ video: true })
        .then((stream) => {
          setVideoStream(stream);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };
  const requestAudio = () => {
    if (navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then((stream) => {
          setAudioStream(stream);
          // setMuted(true);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };
  const handleMute = () => {
    if (audioStream && listening) {
      audioStream.getTracks().forEach((track) => {
        track.enabled = !track.enabled;
      });
      SpeechRecognition.stopListening();
      setMuted(true);
    } else {
      requestAudio();
      SpeechRecognition.startListening({ continuous: true });
      setMuted(false);
    }
  };
  const handleSubmitJD = async (e) => {
    e.preventDefault();
    console.log(
      selectedResumeID,
      uploadedFile,
      jobDescription,
      "selectedResumeID, uploadedFile, jobDescription"
    );
    if (jobDescription.length > 0 && (selectedResumeID || uploadedFile)) {
      let formData = new FormData();
      formData.append("jobDescription", jobDescription);
      if (uploadedFile) {
        formData.append("resumeFile", uploadedFile);
      } else {
        formData.append("resumeId", selectedResumeID);
      }
      // dispatch(
      //   getSingleResume({ resumeId: selectedResumeID, onSuccess: () => {} })
      // );
      dispatch(
        simulatorProBegin({
          payload: formData,
          onSuccess: (res) => {
            setContactDetails(res?.resume_text);
            setIsOpenModal(true);
            // setStartMeet(true);
            // setStep(1);
            // setUploadedFile(null);
            // setSelectedResumeID("");
            // navigate("/simulator-pro?startInterview");
          },
          onError: (err) => {
            error(err?.data?.detail);
          },
        })
      );
    } else {
      error("Please enter your Job Description");
    }
  };
  const handleCamera = () => {
    if (videoStream) {
      const videoTracks = videoStream.getTracks();
      videoTracks.forEach((track) => track.stop());
      setVideoStream(null);
    } else {
      requestVideo();
    }
  };
  /////Screen recorder

  // Start recording (screen + audio)
  const startRecording = async () => {
    try {
      const audioStream = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: true,
      });
      streamRef.current = audioStream;

      const mimeType = "video/webm; codecs=vp8,opus";
      if (!MediaRecorder.isTypeSupported(mimeType)) {
        console.error("MIME type not supported");
        return;
      }

      mediaRecorderRef.current = new MediaRecorder(audioStream, { mimeType });
      mediaRecorderRef.current.ondataavailable = (event) => {
        console.log(event.data, "event.data");
        if (event.data.size > 0) {
          recordedChunks.current.push(event.data);
        }
      };

      mediaRecorderRef.current.onstop = () => {
        const blob = new Blob(recordedChunks.current, { type: "video/webm" });
        console.log(blob, "blob");
        const videoUrl = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = videoUrl;
        a.download = "recorded-screen.webm";
        a.click();
        URL.revokeObjectURL(videoUrl);
        recordedChunks.current = [];
      };

      mediaRecorderRef.current.start();
      setStartMeet(true);
      setStep(1);
      setUploadedFile(null);
      setSelectedResumeID("");
    } catch (error) {
      console.error("Error accessing media devices:", error);
    }
  };

  // Stop recording
  const stopRecording = () => {
    mediaRecorderRef.current.stop();
    streamRef.current.getTracks().forEach((track) => track.stop());
  };

  useEffect(() => {
    if (sessionsId) {
      dispatch(
        validateSessionId({
          sessionsId,
          onError: () => {
            navigate("/");
          },
        })
      );
      dispatch(getPromptById({ id: "simulator_pro", sessionId: sessionsId }));
    } else {
      dispatch(getPromptById({ id: "simulator_pro", sessionId: null }));
    }
  }, [sessionsId]);
  useEffect(() => {
    if (sessionValidation !== null) {
      setStartMeet(true);
      setContactDetails(sessionValidation?.resume_text);
      setJobDescription(sessionValidation?.job_title);
    }
  }, [sessionValidation]);
  useEffect(() => {
    if (startMeet) {
      requestVideo();
      requestAudio();
    }
  }, [startMeet]);
  useEffect(() => {
    if (audioStream && canvasRef.current) {
      const audioContext = new (window.AudioContext ||
        window.webkitAudioContext)();
      const analyser = audioContext.createAnalyser();
      const source = audioContext.createMediaStreamSource(audioStream);
      source.connect(analyser);

      analyser.fftSize = 256;
      const bufferLength = analyser.frequencyBinCount;
      const dataArray = new Uint8Array(bufferLength);

      const canvas = canvasRef.current;
      const canvasCtx = canvas.getContext("2d");
      const drawVisualizer = () => {
        requestAnimationFrame(drawVisualizer);
        analyser.getByteFrequencyData(dataArray);

        const width = canvas.width;
        const height = canvas.height;
        const barWidth = 10;
        canvasCtx.clearRect(0, 0, width, height);
        let x = 0;

        dataArray.forEach((item) => {
          const barHeight = (item / 255) * height * 1.1;
          canvasCtx.strokeStyle = `gray`;
          x += barWidth;
          canvasCtx.beginPath();
          canvasCtx.lineCap = "round";
          canvasCtx.lineWidth = 2;
          canvasCtx.moveTo(x, height);
          canvasCtx.lineTo(x, height - barHeight);
          canvasCtx.stroke();
        });
      };

      drawVisualizer();

      return () => {
        audioContext.close();
      };
    }
  }, [audioStream]);
  useEffect(() => {
    if (!sessionsId) {
      dispatch(getResume({ onSuccess: () => {} }));
    }
    // dispatch(simulatorProBegin());
  }, [sessionsId]);

  return (
    <>
      <Header />
      {step === 2 && !startMeet && (
        <div className="resume-back absolute">
          <button
            onClick={() => {
              navigate(-1);
            }}
            className={`z-50 mt-28 ml-8 bg-white rounded-full size-6 p-1 transition-all duration-500  rotate-180 `}
          >
            <img src={chevronDoubleRightIcon} alt="" />
          </button>
        </div>
      )}
      <div
        className="ic-interview-full-body"
        style={{ backgroundImage: `url(${"/images/interview-banner.png"})` }}
      >
        {startMeet && prompt != "" ? (
          <MeetScreen
            videoStream={videoStream}
            audioStream={audioStream}
            handleMute={handleMute}
            handleCamera={handleCamera}
            handleEndCall={handleEndCall}
            setStartMeet={setStartMeet}
            muted={muted}
            setMuted={setMuted}
            jobDescription={jobDescription}
            sessionId={!sessionsId ? sessionId : sessionsId}
            contactDetails={contactDetails}
            sessionsId={sessionsId}
            interviewTone={interviewTone}
            setInterviewTone={setInterviewTone}
            allowRecording={allowRecording}
          />
        ) : (
          !sessionsId && (
            <div
              className={` d-flex align-items-center justify-content-center`}
            >
              <div className="ic-mockInterview">
                <div className="ic-mock-interview">
                  {step === 1 && (
                    <>
                      <div className="grid divide-neutral-200 -mt-4">
                        <div className="flex justify-content-between items-center nexaInterviewTab-container border-b">
                          <ul className="list-reset flex border-b">
                            <li className="-mb-px mr-1">
                              <a
                                className={`inline-block py-2 px-4 font-semibold ${
                                  activeTab === "upload_resume"
                                    ? " border-l border-t border-r rounded-t text-primary"
                                    : "text-dark"
                                }`}
                                href="#"
                                onClick={() => handleTabClick("upload_resume")}
                              >
                                Upload Resume
                              </a>
                            </li>
                            <li className="mr-1">
                              <a
                                className={`inline-block py-2 px-4 font-semibold ${
                                  activeTab === "existing_resume"
                                    ? " border-l border-t border-r rounded-t text-primary"
                                    : "text-dark"
                                }`}
                                href="#"
                                onClick={() =>
                                  handleTabClick("existing_resume")
                                }
                              >
                                Select Resume
                              </a>
                            </li>
                          </ul>
                        </div>
                        {activeTab === "upload_resume" ? (
                          <>
                            <div className="py-4">
                              <span className="font-medium">
                                Upload Your Resume For Personal Interview Coming
                                Up
                              </span>
                            </div>
                            <div className="pt-1  group-open:animate-fadeIn">
                              <form action="#">
                                <input
                                  type="file"
                                  name="file"
                                  ref={fileInput}
                                  accept=".pdf"
                                  hidden
                                  onChange={(e) => getFile(e)}
                                />
                                <div
                                  className="ic-file-upload"
                                  onClick={() => {
                                    fileInput.current.click();
                                  }}
                                >
                                  <div className="ic-icon">
                                    <img
                                      src="/images/document-upload.png"
                                      alt="Upload Document"
                                      style={{ width: "auto" }}
                                    />
                                  </div>
                                  <p className="support-text cursor-pointer">
                                    <span className="primary">
                                      Click to Upload
                                    </span>{" "}
                                    or drag and drop
                                  </p>
                                  <span className="ic-file-size">
                                    (Max. File size: 2.5 MB)
                                  </span>
                                </div>
                              </form>

                              {uploadedFile && (
                                <div className="ic-loading-area">
                                  <ul>
                                    <li>
                                      <div className="file-name">
                                        <div className="file-icon">
                                          <FaRegFilePdf
                                            size={24}
                                            color="#2B6BE2"
                                          />
                                        </div>
                                        <div className="file-size">
                                          <div>
                                            <p>{uploadedFile.name}</p>
                                            {uploadedFile.size && (
                                              <span>{uploadedFile.size}</span>
                                            )}
                                          </div>
                                          {uploadedFile.loading === 100 && (
                                            <i className="ri-checkbox-circle-fill"></i>
                                          )}
                                        </div>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              )}

                              {uploadedFile && (
                                <div
                                  className="mt-2 ic-btn"
                                  type="button"
                                  onClick={() => setStep(2)}
                                >
                                  Next
                                </div>
                              )}
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="py-4">
                              <span className="font-medium">
                                Select From Existing Resumes?
                              </span>
                            </div>
                            <form className="w-full py-1 mb-3 -mt-3 group-open:animate-fadeIn">
                              <fieldset>
                                <div className="text-gray-800">
                                  {allResume?.filter(
                                    (item) => item?.isCompleted
                                  ).length > 0 ? (
                                    <Select
                                      placeholder="Select Resume"
                                      value={
                                        selectedResumeID || "Select Resume"
                                      }
                                      onChange={(value) =>
                                        setSelectedResumeID(value)
                                      }
                                      className="w-full mt-1 h-[48px]"
                                    >
                                      {allResume
                                        ?.filter((item) => item?.isCompleted)
                                        ?.map((resume) => {
                                          return (
                                            <Option
                                              key={resume.id}
                                              value={resume.id}
                                            >
                                              {resume?.resume_name
                                                ? `${resume.resume_name}(${
                                                    resume?.iterations
                                                  })${
                                                    resume.company_name
                                                      ? ` - ${resume.company_name}`
                                                      : ""
                                                  }`
                                                : `${resume?.contact?.first_name} ${resume?.contact?.last_name}`}
                                            </Option>
                                          );
                                        })}
                                    </Select>
                                  ) : (
                                    <p className="text-yellow-600 p-1">
                                      You currently don't have any completed
                                      resumes in your profile. Please visit the{" "}
                                      <Link
                                        to="/resumes"
                                        className="text-yellow-600 underline"
                                      >
                                        Resume Page
                                      </Link>{" "}
                                      to create and complete a resume for use in
                                      Nexa.
                                    </p>
                                  )}
                                </div>
                              </fieldset>
                            </form>
                            {selectedResumeID !== "" && (
                              <div
                                className="mt-2 ic-btn"
                                type="button"
                                onClick={() => setStep(2)}
                              >
                                Next
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </>
                  )}
                  {step === 2 && (
                    <div>
                      <h4>
                        Welcome to the Nexa Interview Simulator, you can now
                        step into any interview for any career you want to be
                        the best candidate. Simply fill out the information
                        below and press start!
                      </h4>
                      <div className="text-area">
                        <textarea
                          placeholder="Type your Job Description..."
                          required
                          value={jobDescription}
                          onChange={(e) => setJobDescription(e.target.value)}
                        />
                      </div>
                      <Select
                        placeholder="Select Resume"
                        value={interviewTone || "Select Interview Tone"}
                        onChange={(value) => setInterviewTone(value)}
                        className="w-full mt-1 h-[48px]"
                      >
                        {Tones?.map((tone, index) => {
                          return (
                            <Option key={index} value={tone.value}>
                              {tone?.label}
                            </Option>
                          );
                        })}
                      </Select>
                      <button
                        onClick={handleSubmitJD}
                        className="ic-btn mt-3 disabled:opacity-50 disabled:cursor-not-allowed"
                        disabled={!jobDescription || !interviewTone}
                      >
                        Start Interview <i className="ri-file-list-line"></i>
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )
        )}
      </div>
      <div className={`ic-modal ${isOpenModal ? "show" : ""}`}>
        <div className="ic-modal-dialog">
          <div className="modal-main-content w-100">
            <div className="ic-modal-body">
              <div className="ic-modal-content">
                <p className="text-xl">
                  Are you sure you want to record and download your interview?
                </p>
              </div>
              <div className="ic-footer-modal d-flex justify-content-end">
                <button
                  onClick={() => {
                    setIsOpenModal(false);
                    setStartMeet(true);
                    setStep(1);
                    setUploadedFile(null);
                    setSelectedResumeID("");
                    navigate("/simulator-pro?startInterview");
                  }}
                >
                  No
                </button>
                <button
                  onClick={() => {
                    setIsOpenModal(false);
                    setAllowRecording(true);
                    navigate("/simulator-pro?startInterview");
                    startRecording();
                  }}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SimulatorPro;
