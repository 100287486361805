import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RepositoryFactory } from "../repository/RepositoryFactory";
import { error, success } from "../Utilities/toast";

const profileRepo = RepositoryFactory.get("UserProfile");

////Add New Skills on profile Section
export const AddSkills = createAsyncThunk(
  "profile/AddSkills",
  async ({ skills, onSuccess }, { rejectWithValue }) => {
    try {
      const { data } = await profileRepo.addNewSkill(skills);
      if (data) {
        onSuccess();
        return data.data.data;
      }
      throw new Error("Fetch failed");
    } catch (err) {
      error(err.response.data.detail || "Something went wrong");
      return rejectWithValue(err.message);
    }
  }
);
//// Delete skills on profile Section
export const deleteSkill = createAsyncThunk(
  "profile/deleteSkill",
  async ({ id, onSuccess }, { rejectWithValue }) => {
    try {
      const { data } = await profileRepo.deleteSkill(id);
      if (data) {
        onSuccess();
        return data;
      }
      throw new Error("Fetch failed");
    } catch (err) {
      error(err.response.data.detail || "Something went wrong");
      return rejectWithValue(err.message);
    }
  }
);

///Add Certificates on profile Setion

export const AddCertifications = createAsyncThunk(
  "profile/AddCertifications",
  async ({ certifications, onSuccess }, { rejectWithValue }) => {
    try {
      const { data } = await profileRepo.AddNewCertifications(certifications);
      if (data) {
        onSuccess();
        return data;
      }
      throw new Error("Fetch failed");
    } catch (err) {
      error(err.response.data.detail || "Something went wrong");
      return rejectWithValue(err.message);
    }
  }
);

///Delete User certificates
export const deleteCertification = createAsyncThunk(
  "profile/deleteCertification",
  async ({ id, onSuccess }, { rejectWithValue }) => {
    try {
      const { data } = await profileRepo.deleteCertification(id);
      if (data) {
        onSuccess();
        return data;
      }
      throw new Error("Fetch failed");
    } catch (err) {
      error(err.response.data.detail || "Something went wrong");
      return rejectWithValue(err.message);
    }
  }
);

/////Add New Work Sample
export const AddWorkSamples = createAsyncThunk(
  "profile/AddWorkSamples",
  async ({ payload, onSuccess }, { rejectWithValue }) => {
    try {
      const { data } = await profileRepo.AddWorkSamples(payload);
      if (data) {
        onSuccess();
        return data;
      }
      throw new Error("Fetch failed");
    } catch (err) {
      error(err.response.data.detail || "Something went wrong");
      return rejectWithValue(err.message);
    }
  }
);

///////Delete Work Sample
export const deleteProjects = createAsyncThunk(
  "profile/deleteProjects",
  async ({ id, onSuccess }, { rejectWithValue }) => {
    try {
      const { data } = await profileRepo.deleteProjects(id);
      if (data) {
        onSuccess();
        return data;
      }
      throw new Error("Fetch failed");
    } catch (err) {
      error(err.response.data.detail || "Something went wrong");
      return rejectWithValue(err.message);
    }
  }
);

///Add Work Experience
export const AddExperienceHistory = createAsyncThunk(
  "profile/AddExperienceHistory",
  async ({ payload, onSuccess }, { rejectWithValue }) => {
    try {
      const { data } = await profileRepo.AddExperienceHistory(payload);
      if (data) {
        onSuccess();
        return data;
      }
      throw new Error("Fetch failed");
    } catch (err) {
      error(err.response.data.detail || "Something went wrong");
      return rejectWithValue(err.message);
    }
  }
);

///////Delete Experience History
export const deleteExperienceHistory = createAsyncThunk(
  "profile/deleteExperienceHistory",
  async ({ id, onSuccess }, { rejectWithValue }) => {
    try {
      const { data } = await profileRepo.deleteExperienceHistory(id);
      if (data) {
        onSuccess();
        return data;
      }
      throw new Error("Fetch failed");
    } catch (err) {
      error(err.response.data.detail || "Something went wrong");
      return rejectWithValue(err.message);
    }
  }
);

///Add Job Preference
export const addJobPreference = createAsyncThunk(
  "profile/addJobPreference",
  async ({ payload, onSuccess }, { rejectWithValue }) => {
    try {
      const { data } = await profileRepo.addJobPreference(payload);
      if (data) {
        onSuccess();
        return data;
      }
      throw new Error("Fetch failed");
    } catch (err) {
      error(err.response.data.detail || "Something went wrong");
      return rejectWithValue(err.message);
    }
  }
);

///////Delete Job Preference
export const deleteJobPreference = createAsyncThunk(
  "profile/deleteJobPreference",
  async ({ id, onSuccess }, { rejectWithValue }) => {
    try {
      const { data } = await profileRepo.deleteJobPreference(id);
      if (data) {
        onSuccess();
        return data;
      }
      throw new Error("Fetch failed");
    } catch (err) {
      error(err.response.data.detail || "Something went wrong");
      return rejectWithValue(err.message);
    }
  }
);

////Get complete simulation count
export const getCompletedSimulatorCount = createAsyncThunk(
  "profile/getCompletedSimulatorCount",
  async ({ onSuccess }, { rejectWithValue }) => {
    try {
      const { data } = await profileRepo.getCompletedSimulatorCount();
      if (data) {
        onSuccess();
        return data.data.categories_with_id;
      }
      throw new Error("Fetch failed");
    } catch (err) {
      error(err.response.data.detail || "Something went wrong");
      return rejectWithValue(err.message);
    }
  }
);

////Get complete Interview sessions
export const getCompletedInterviewSessions = createAsyncThunk(
  "profile/getCompletedInterviewSessions",
  async ({ onSuccess }, { rejectWithValue }) => {
    try {
      const { data } = await profileRepo.getCompletedInterviewSessions();
      if (data) {
        onSuccess();
        return data.data.session_details;
      }
      throw new Error("Fetch failed");
    } catch (err) {
      error(err.response.data.detail || "Something went wrong");
      return rejectWithValue(err.message);
    }
  }
);

////update saveShowcaseTalent
export const saveShowcaseTalent = createAsyncThunk(
  "profile/saveShowcaseTalent",
  async ({ payload, onSuccess }, { rejectWithValue }) => {
    try {
      const { data } = await profileRepo.saveShowcaseTalent(payload);
      if (data) {
        onSuccess();
        return data.data.categories_with_id;
      }
      throw new Error("Fetch failed");
    } catch (err) {
      error(err.response.data.detail || "Something went wrong");
      return rejectWithValue(err.message);
    }
  }
);

////Upload Certitificate Files
export const uploadCertiticateFiles = createAsyncThunk(
  "profile/uploadCertiticateFiles",
  async ({ payload, onSuccess }, { rejectWithValue }) => {
    console.log(payload, "certificationsbyrepo");
    try {
      const { data } = await profileRepo.uploadCertiticateFiles(payload);
      if (data) {
        onSuccess(data.data);
        return data.data;
      }
      throw new Error("Fetch failed");
    } catch (err) {
      error(err.response.data.detail || "Something went wrong");
      return rejectWithValue(err.message);
    }
  }
);

////DELETE Certitificate Files
export const deleteCertificationFile = createAsyncThunk(
  "profile/deleteCertificationFile",
  async ({ file, onSuccess }, { rejectWithValue }) => {
    try {
      const { data } = await profileRepo.deleteCertificationFile(file);
      if (data) {
        onSuccess(data.data);
        return data.data;
      }
      throw new Error("Fetch failed");
    } catch (err) {
      error(err.response.data.detail || "Something went wrong");
      return rejectWithValue(err.message);
    }
  }
);

////Get job Roles
export const getJobRoles = createAsyncThunk(
  "profile/getJobRoles",
  async ({ onSuccess }, { rejectWithValue }) => {
    try {
      const { data } = await profileRepo.getJobRoles();
      if (data) {
        onSuccess();
        return data.data.professions;
      }
      throw new Error("Fetch failed");
    } catch (err) {
      error(err.response.data.detail || "Something went wrong");
      return rejectWithValue(err.message);
    }
  }
);

////Get key Skills
export const getkeySkills = createAsyncThunk(
  "profile/getkeySkills",
  async ({ education, onSuccess }, { rejectWithValue }) => {
    try {
      const { data } = await profileRepo.getkeySkills(education);
      if (data) {
        const formattedSkills = data.data.skills.map((skill) => ({
          value: skill,
          label: skill,
        }));
        onSuccess();
        return formattedSkills;
      }
      throw new Error("Fetch failed");
    } catch (err) {
      error(err.response.data.detail || "Something went wrong");
      return rejectWithValue(err.message);
    }
  }
);

const UserProfileSlice = createSlice({
  name: "profile",
  initialState: {
    loading: false,
    keySkillsLoading: false,
    simulatorCount: [],
    interviewSessions: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      //// Create New Skills Slices
      .addCase(AddSkills.pending, (state) => {
        state.loading = true;
      })
      .addCase(AddSkills.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(AddSkills.rejected, (state) => {
        state.loading = false;
      })
      //// Delete Skills Slices
      .addCase(deleteSkill.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteSkill.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteSkill.rejected, (state) => {
        state.loading = false;
      })
      //// Create New Certificates Slices
      .addCase(AddCertifications.pending, (state) => {
        state.loading = true;
      })
      .addCase(AddCertifications.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(AddCertifications.rejected, (state) => {
        state.loading = false;
      })
      //// Delete Certificates Slices
      .addCase(deleteCertification.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteCertification.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteCertification.rejected, (state) => {
        state.loading = false;
      })
      ///Add Worksamples Slices
      .addCase(AddWorkSamples.pending, (state) => {
        state.loading = true;
      })
      .addCase(AddWorkSamples.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(AddWorkSamples.rejected, (state) => {
        state.loading = false;
      })
      ///Delete Worksamples Slices
      .addCase(deleteProjects.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteProjects.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteProjects.rejected, (state) => {
        state.loading = false;
      })
      ///Add Work Experience Slices
      .addCase(AddExperienceHistory.pending, (state) => {
        state.loading = true;
      })
      .addCase(AddExperienceHistory.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(AddExperienceHistory.rejected, (state) => {
        state.loading = false;
      })
      ///Delete Work Experience Slices
      .addCase(deleteExperienceHistory.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteExperienceHistory.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteExperienceHistory.rejected, (state) => {
        state.loading = false;
      })
      ///Add Job Preference Slices
      .addCase(addJobPreference.pending, (state) => {
        state.loading = true;
      })
      .addCase(addJobPreference.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(addJobPreference.rejected, (state) => {
        state.loading = false;
      })
      ///Delete Job Preference Slices
      .addCase(deleteJobPreference.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteJobPreference.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteJobPreference.rejected, (state) => {
        state.loading = false;
      })
      //// Get complete simulation count slices
      .addCase(getCompletedSimulatorCount.fulfilled, (state, action) => {
        state.simulatorCount = action.payload;
      })
      //// Get complete Interview sessions slices
      .addCase(getCompletedInterviewSessions.fulfilled, (state, action) => {
        state.interviewSessions = action.payload;
      })
      ///update Show case talent Slices
      .addCase(saveShowcaseTalent.pending, (state) => {
        state.loading = true;
      })
      .addCase(saveShowcaseTalent.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(saveShowcaseTalent.rejected, (state) => {
        state.loading = false;
      })
      ////Upload Certitificate Files Slices
      .addCase(uploadCertiticateFiles.pending, (state) => {
        state.loading = true;
      })
      .addCase(uploadCertiticateFiles.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(uploadCertiticateFiles.rejected, (state) => {
        state.loading = false;
      })
      ////DELETE Certitificate File Slices
      .addCase(deleteCertificationFile.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteCertificationFile.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteCertificationFile.rejected, (state) => {
        state.loading = false;
      })
      //// Get Job roles slices
      .addCase(getJobRoles.fulfilled, (state, action) => {
        state.jobRoles = action.payload;
      })
      //// Get Key Skills slices
      .addCase(getkeySkills.pending, (state, action) => {
        state.keySkillsLoading = true;
      })
      .addCase(getkeySkills.fulfilled, (state, action) => {
        state.keySkillsLoading = false;
        state.keySkills = action.payload;
      })
      .addCase(getkeySkills.rejected, (state, action) => {
        state.keySkillsLoading = false;
      });
  },
});

export default UserProfileSlice.reducer;
