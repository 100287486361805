import { useDispatch, useSelector } from "react-redux";
import ReactMarkdown from "react-markdown";
import Header from "../../Components/header";
import "./user.css";
import Modal from "../../Components/modal";
import { useEffect, useState } from "react";
import moment from "moment";
import {
  interviewTrainingAnalytics,
  interviewSimulationAnalytics,
  FeedbackData,
  TrainingSessionData,
  GetInterviewSimulationsData,
  FeedbackSimulationData,
} from "../../Redux/ProfileAnalysticsSlice";
import { useNavigate } from "react-router-dom";
import TypingEffect from "../../Utilities/TypingEffect";
import { getSkillsAnalysis } from "../../Redux/authenticate/authenticate.action";
import Loader from "../../Components/main-loader";
import { FaAngleDoubleDown, FaAngleDoubleUp } from "react-icons/fa";
import TruncateText from "./TruncateText";
import Pagination from "../../Utilities/Pagination";
import CircularLoader from "../../Components/loader";
import { error } from "../../Utilities/toast";

const UserDashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    interviewStats,
    interviewSimulationStats,
    simulationList,
    feedbacks,
    trainingSessionData,
    trainingPagination,
    simulationPagination,
    isLoading,
  } = useSelector((state) => state.profile);
  console.log("feedbacks", feedbacks);
  const [practicePage, setPracticePage] = useState(1);
  const [feedbackLoading, setFeedbackLoading] = useState(false);
  const [simulationFeedback, setSimulationFeedback] = useState(false);
  const [simulationPage, setSimulationPage] = useState(1);
  // const [Data, setData] = useState([]);
  // console.log({ simulationList });
  const [isModal, setIsModal] = useState(false);
  const [handleToogle, sethandleToogle] = useState(false);
  const handleOpenModal = async (id, type) => {
    window.scrollTo(0, 0);

    setFeedbackLoading(true);

    let payload = {
      id,
      type,
    };

    if (type === "simulation") {
      dispatch(FeedbackSimulationData(payload))
        .unwrap()
        .then((res) => {
          setSimulationFeedback(true);
          setFeedbackLoading(false);
          setIsModal(true);
        })
        .catch((err) => {
          error(
            err?.message ||
              "There was an error while fetching feedback! Please try again later"
          );
          setFeedbackLoading(false);
          setIsModal(false);
        });
    } else {
      dispatch(FeedbackData(payload))
        .unwrap()
        .then((res) => {
          // if (type === "simulation") {
          //   setSimulationFeedback(true);
          // } else {
          setSimulationFeedback(false);
          // }
          setFeedbackLoading(false);
          setIsModal(true);
        })
        .catch((err) => {
          error(
            err?.message ||
              "There was an error while fetching feedback! Please try again later"
          );
          setFeedbackLoading(false);
          setIsModal(false);
        });
    }

    // dispatch(FeedbackData(payload))
    //   .unwrap()
    //   .then((res) => {
    //     if (type === "simulation") {
    //       setSimulationFeedback(true);
    //     } else {
    //       setSimulationFeedback(false);
    //     }
    //     setFeedbackLoading(false);
    //     setIsModal(true);
    //   })
    //   .catch((err) => {
    //     error(
    //       err?.message ||
    //         "There was an error while fetching feedback! Please try again later"
    //     );
    //     setFeedbackLoading(false);
    //     setIsModal(false);
    //   });
  };
  const handleCloseModal = () => {
    setIsModal(false);
  };
  // useEffect(() => {
  //   if (simulationList?.length || trainingSessionData?.length) {
  //     setData((prev) => [...simulationList, ...trainingSessionData]);
  //   }
  // }, [simulationList, trainingSessionData]);

  const getModal = () => {
    const header = (
      <div className="mockInterview-modal-header">
        <button className="modal-close" onClick={() => handleCloseModal()}>
          <i className="ri-close-line"></i>
        </button>
      </div>
    );

    const content = (
      <div className="row">
        <div className="col-12 order-md-1 order-2">
          {!simulationFeedback ? (
            feedbacks.data ? (
              <>
                {(feedbacks?.data?.session_type !== "simulation-pro"
                  ? feedbacks?.feedbackList?.slice().reverse()
                  : feedbacks?.feedbackList
                )?.map((item, index) => (
                  <div className="cardBody" key={index}>
                    <h5 className="mx-3 my-3">Question:</h5>
                    <hr />
                    <p className="mx-3 my-3">{item?.question}</p>
                    <hr />
                    <h5 className="mx-3 my-3 text-primary">Answer:</h5>
                    <hr />
                    <h6 className="mx-3 my-3 text-primary">{item?.answer}</h6>
                    <hr />
                    <h5 className="mx-3 my-3 text-primary">Feedback:</h5>
                    <ReactMarkdown className="mx-3 my-3">
                      {item?.feedback}
                    </ReactMarkdown>
                  </div>
                ))}
              </>
            ) : (
              <>
                {feedbacks?.feedbackList?.map((item, index) => (
                  <div className="cardBody" key={index}>
                    <h5 className="mx-3 my-3 ">Question:</h5>
                    <hr />
                    <p className="mx-3 my-3">{item?.question}</p>
                    <hr />
                    <h5 className="mx-3 my-3 text-primary">Answer:</h5>
                    <hr />
                    <h6 className="mx-3 my-3 text-primary">{item?.answer}</h6>
                    <hr />
                    <h5 className="mx-3 my-3 text-primary">Feedback:</h5>
                    <ReactMarkdown className="mx-3 my-3">
                      {item?.feedback}
                    </ReactMarkdown>
                  </div>
                ))}
              </>
            )
          ) : (
            <>
              <div className="cardBody">
                <h3 className="mx-3 my-3 font-bold">Generalized Report:</h3>
                <hr />
                <p className="mx-3 my-3">{feedbacks?.generalized_report}</p>
              </div>
              <div className="cardBody">
                <h3 className="mx-3 my-3 font-bold">Suggested Skill Improvements:</h3>
                <hr />
                <p className="mx-3 my-3">
                  {feedbacks?.suggested_skill_improvements}
                </p>
              </div>
              <div className="cardBody">
                <h3 className="mx-3 my-3 font-bold ">
                  Strength and positive point:
                </h3>
                <hr />
                <p className="mx-3 my-3">
                  {feedbacks?.key_strengths_and_positive_points}
                </p>
              </div>
            </>
          )}
        </div>
      </div>
    );

    const footer = (
      <div className="footer_scrore d-flex">
        <h5 className="my-0">Nexa Grading Score:</h5>
        <span className="score">
          {feedbacks?.averageScore?.toFixed(2) || 0}
        </span>
      </div>
    );

    return (
      <Modal show={isModal} header={header} content={content} footer={footer} />
    );
  };
  // const fetchedData = {
  //   totalInterviewTaken: 0,
  //   interviewsFeedback: 0,
  //   interviewSeesionTaken: 0,
  //   interviewMutualFeedback: 0,
  // };

  const handleToogleFunction = () => {
    sethandleToogle((prev) => !prev);
  };

  /*Remove this from comment when you get a real API
const fetchData = fetch("backend/userDashboard");*/
  const { user } = useSelector((state) => state.authenticate);
  // let [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    dispatch(interviewTrainingAnalytics());
    dispatch(interviewSimulationAnalytics());
    dispatch(getSkillsAnalysis({ onSuccess: () => {} }));
  }, []);

  useEffect(() => {
    dispatch(TrainingSessionData(practicePage));
  }, [practicePage]);
  useEffect(() => {
    dispatch(GetInterviewSimulationsData(simulationPage));
  }, [simulationPage]);

  return (
    <>
      <Header />
      {/* <div
        style={{
          position: "absolute",
          top: "10vh",
          bottom: "10vh",
          left: "15vw",
          right: "10vw",
          backgroundColor: "white",
          border: "1px solid",
          borderColor: "gray",
          borderRadius: "20px",
          zIndex: 10,
          boxShadow: "10px 10px 40px gray",
          padding: "30px",
          visibility: isOpen ? "visible" : "hidden",
        }}
      >
        <h3 className="inline text-bold text-primary text-md w-[100vw]">
          Examination Details
        </h3>
        <span
          onClick={() => {
            setIsOpen(false);
          }}
          style={{
            position: "absolute",
            right: "4vw",
            fontSize: "30px",
            fontWeight: "800",
            top: "20px",
          }}
          className="inline text-primary font-bold ml-auto"
        >
          X
        </span>

        <hr />
        <table
          border={1}
          style={{ width: "100%", borderRadius: "20px" }}
          className="table rounded-lg table-hover w-full"
        >
          <thead>
            <tr>
              <th>#</th>
              <th>Date/Time of Exam</th>
              <th>Category of Exam</th>
              <th>Feedback</th>
            </tr>
          </thead>
        </table>
      </div> */}

      {!feedbackLoading ? (
        <>
          <div
            className="ic-interview-full-body overflow-hidden"
            style={{
              backgroundImage: `url(${"/images/interview-banner.png"})`,
              paddingTop: "6em",
            }}
          >
            {/* <!-- Content --> */}
            <main
              role="main"
              className="ml-sm-auto col-lg-12 px-4 pb-5"
              style={{
                backgroundImage: `url(${"/images/interview-banner.png"})`,
              }}
            >
              <div className="pt-3 pb-5 mb-3">
                <h1 className="h2">Hello, {user?.name}</h1>
                <p>We are Glad to see you again!</p>
              </div>
              <div className="content">
                <div className="row gap-y-10">
                  <div className="col-xl-4 col-lg-6 col-sm-6 grid-margin stretch-card">
                    <div
                      className="profile-card border-rounded-card card-padding-profile"
                      style={{ boxShadow: "10px 10px 40px gray" }}
                    >
                      <div
                        // onClick={() => {
                        //   setIsOpen(trufdasfe);
                        // }}
                        className="card-body text-center justify-content-between stat-card"
                      >
                        <div className="d-flex flex-column justify-content-left  ">
                          <div className="card-icon-box bg-color-black">
                            <i className="ri-user-6-line h1 text-primary"></i>
                          </div>
                          <p className="mb-2 text-dark font-weight-normal text-right">
                            Total Interviews Taken
                          </p>
                          <h3 className="text-dark font-weight-bold text-right">
                            {(interviewStats?.total_sessions || 0) +
                              (interviewSimulationStats?.total_sessions || 0)}
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-6 col-sm-6 grid-margin stretch-card">
                    <div
                      className="profile-card border-rounded-card card-padding-profile"
                      style={{ boxShadow: "10px 10px 40px gray" }}
                    >
                      <div
                        // onClick={() => {
                        //   setIsOpen(true);
                        // }}
                        className="card-body text-center justify-content-between stat-card"
                      >
                        <div className="d-flex flex-column justify-content-left  ">
                          <div className="card-icon-box bg-color-blue">
                            <i
                              className="ri-chat-3-line h1 text-primary"
                              color="white"
                            ></i>
                          </div>
                          <p className="mb-2 text-dark font-weight-normal text-right">
                            Total Practice Rounds
                          </p>
                          <h3 className="text-dark font-weight-bold text-right">
                            {interviewStats?.total_sessions || 0}
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4  col-lg-6 col-sm-6 grid-margin stretch-card">
                    <div
                      className="profile-card border-rounded-card card-padding-profile"
                      style={{ boxShadow: "10px 10px 40px gray" }}
                    >
                      <div
                        // onClick={() => {
                        //   setIsOpen(true);
                        // }}
                        className="card-body text-center  justify-content-between stat-card "
                      >
                        <div className="d-flex flex-column justify-content-left  ">
                          <div className="card-icon-box bg-color-green">
                            <i className="ri-profile-line h1 text-primary"></i>
                          </div>
                          <p className="mb-2 text-dark font-weight-normal text-right">
                            Total Interview Simulations
                          </p>
                          <h3 className="text-dark font-weight-bold text-right">
                            {interviewSimulationStats?.total_sessions || 0}
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="grid-margin stretch-card my-3">
                <div
                  className="profile-card border-rounded-card card-padding-profile relative"
                  style={{ boxShadow: "10px 10px 40px gray" }}
                >
                  <div
                    className={`card-body py-0 ${
                      handleToogle
                        ? "max-h-auto overflow-auto"
                        : "max-h-[300px] overflow-hidden"
                    }`}
                  >
                    <h3 className="skills-heading">Skills Gap Assessment</h3>
                    <TypingEffect storeFetchValue={""} />
                    {/* <div
                  dangerouslySetInnerHTML={SkillsGapConvertion(
                    skillsAnalysis && skillsAnalysis.analysis
                  )}
                ></div> */}
                    {/* <p>skills gap response </p> */}
                    <div className="d-flex justify-content-end align-items-center mt-4">
                      <button
                        className="ic-btn"
                        onClick={() => {
                          navigate("/user2/home");
                        }}
                      >
                        Retake
                      </button>
                    </div>
                    {handleToogle ? (
                      <div className="flex justify-center items-center absolute top-[96%] left-0 right-0">
                        <span
                          onClick={() => handleToogleFunction()}
                          className="cursor-pointer"
                        >
                          <FaAngleDoubleUp size={25} />
                        </span>
                      </div>
                    ) : (
                      <div className="flex justify-center items-center absolute top-[88%] left-0 right-0">
                        <span
                          onClick={() => handleToogleFunction()}
                          className="cursor-pointer"
                        >
                          <FaAngleDoubleDown size={25} />
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div
                style={{ marginTop: "20px" }}
                className="w-full grid-margin stretch-card"
              >
                <div
                  className="profile-card border-rounded-card card-padding-profile2 !overflow-scroll"
                  style={{ boxShadow: "10px 10px 40px gray" }}
                >
                  <div className="card-body text-center justify-content-between card-body-mobile">
                    <h3 className="text-bold text-primary text-md w-[70vw] h-[4vw] mb-4 md:!mb-0">
                      Practice Rounds
                    </h3>

                    <hr />

                    <div className="flex flex-col">
                      <div className="overflow-x-auto sm:mx-0.5 lg:mx-0.5">
                        <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                          <div className="overflow-hidden">
                            <table className="min-w-full">
                              <thead className="bg-gray-200 border-b">
                                <tr>
                                  <th
                                    scope="col"
                                    className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                                  >
                                    Date of Interview
                                  </th>
                                  <th
                                    scope="col"
                                    className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                                  >
                                    Category
                                  </th>
                                  <th
                                    scope="col"
                                    className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                                  >
                                    Feedback
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {trainingSessionData &&
                                trainingSessionData?.length > 0 ? (
                                  <>
                                    {trainingSessionData?.map(
                                      (feedback, index) => {
                                        const timestamp = feedback?.created_on;
                                        const formattedDateTime = moment(
                                          timestamp
                                        ).format("DD/MM/YYYY HH:mm:ss A");
                                        return (
                                          <>
                                            <tr className="bg-white border-b text-start transition duration-300 ease-in-out hover:bg-gray-100">
                                              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                                {formattedDateTime}
                                              </td>
                                              <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                <TruncateText
                                                  text={
                                                    feedback?.job_description
                                                      ? feedback?.job_description
                                                      : feedback?.role_category
                                                  }
                                                />
                                              </td>
                                              <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                <button
                                                  disabled={isLoading}
                                                  className={"ic-btn"}
                                                  style={{
                                                    fontSize: "16px",
                                                    fontWeight: "500",
                                                  }}
                                                  onClick={() =>
                                                    handleOpenModal(
                                                      feedback?.id,
                                                      "practice"
                                                    )
                                                  }
                                                >
                                                  {isLoading ? (
                                                    <Loader />
                                                  ) : (
                                                    "Feedback"
                                                  )}
                                                </button>
                                              </td>
                                            </tr>
                                          </>
                                        );
                                      }
                                    )}
                                  </>
                                ) : (
                                  <>
                                    <tr className="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100">
                                      <td
                                        colSpan={4}
                                        className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                                      >
                                        No Interviews found
                                      </td>
                                    </tr>
                                  </>
                                )}
                              </tbody>
                            </table>
                          </div>
                          <Pagination
                            currentPage={practicePage}
                            totalPages={trainingPagination?.totalPages}
                            onPageChange={setPracticePage}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{ marginTop: "20px" }}
                className="w-full grid-margin stretch-card"
              >
                <div
                  className="profile-card border-rounded-card card-padding-profile !overflow-scroll"
                  style={{ boxShadow: "10px 10px 40px gray" }}
                >
                  <div className="card-body text-center justify-content-between card-body-mobile">
                    <h3 className="text-bold text-primary text-md w-[70vw] h-[4vw] mb-4 md:!mb-0">
                      Interview Simulations
                    </h3>

                    <hr />
                    <div className="flex flex-col">
                      <div className="overflow-x-auto sm:mx-0.5 lg:mx-0.5">
                        <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                          <div className="overflow-hidden">
                            <table className="min-w-full">
                              <thead className="bg-gray-200 border-b">
                                <tr>
                                  <th
                                    scope="col"
                                    className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                                  >
                                    Date of Interview
                                  </th>
                                  <th
                                    scope="col"
                                    className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                                  >
                                    Category
                                  </th>
                                  <th
                                    scope="col"
                                    className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                                  >
                                    Feedback
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {simulationList &&
                                simulationList?.length > 0 ? (
                                  <>
                                    {simulationList?.map((feedback, index) => {
                                      const timestamp = feedback?.created_on;
                                      const formattedDateTime = moment(
                                        timestamp
                                      ).format("DD/MM/YYYY HH:mm:ss A");
                                      return (
                                        <>
                                          <tr className="bg-white border-b text-start transition duration-300 ease-in-out hover:bg-gray-100">
                                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                              {formattedDateTime}
                                            </td>
                                            <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                              <TruncateText
                                                text={
                                                  feedback?.job_description
                                                    ? feedback?.job_description
                                                    : feedback?.role_category
                                                }
                                              />
                                            </td>
                                            <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                              <button
                                                disabled={
                                                  isLoading ||
                                                  (feedback?.type ===
                                                    "simulation-pro" &&
                                                    !feedback?.feedback_status)
                                                }
                                                className={
                                                  "ic-btn disabled:bg-gray-200 disabled:text-gray-500"
                                                }
                                                style={{
                                                  fontSize: "16px",
                                                  fontWeight: "500",
                                                }}
                                                onClick={() =>
                                                  handleOpenModal(
                                                    feedback?.id,
                                                    "simulation"
                                                  )
                                                }
                                              >
                                                {isLoading ? (
                                                  <Loader />
                                                ) : (
                                                  <>
                                                    {feedback?.type ===
                                                      "simulation-pro" &&
                                                    !feedback?.feedback_status
                                                      ? "Processing"
                                                      : "Feedback"}
                                                  </>
                                                )}
                                              </button>
                                            </td>
                                          </tr>
                                        </>
                                      );
                                    })}
                                  </>
                                ) : (
                                  <>
                                    <tr className="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100">
                                      <td
                                        colSpan={4}
                                        className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                                      >
                                        No Interview Simulations found
                                      </td>
                                    </tr>
                                  </>
                                )}
                              </tbody>
                            </table>
                          </div>
                          <Pagination
                            currentPage={simulationPage}
                            totalPages={simulationPagination?.totalPages}
                            onPageChange={setSimulationPage}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </main>
            {getModal()}
          </div>
        </>
      ) : (
        <CircularLoader
          title="Nexa is hard at work to analyze your results, give us a minute"
          icon={true}
        />
      )}
    </>
  );
};

export default UserDashboard;
