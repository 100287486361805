import React, { useEffect, useRef, useState } from "react";
import { MdDelete, MdFileUpload } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { updateOnboardingUser } from "../../../Redux/authenticate/authenticate.action";
import { Autocomplete } from "@react-google-maps/api";
import Loader from "../../../Components/main-loader";
import { error, success } from "../../../Utilities/toast";

const UserInfo = ({
  userProfileInfo,
  setUserProfileInfo,
  activeStep,
  setActiveStep,
}) => {
  const dispatch = useDispatch();
  const autocompleteRef = useRef(null);
  const { user, loading } = useSelector((state) => state.authenticate);
  const [initialUserProfileInfo, setInitialUserProfileInfo] = useState({});
  const handleProfileChange = (e) => {
    const { name, value, type, files } = e.target;

    if (name === "contact" && !/^\d*$/.test(value)) {
      return;
    }

    if (type === "file") {
      const file = files[0];
      const validImageTypes = ["image/jpeg", "image/png", "image/jpg"];
      const maxSizeInMB = 2; // 2MB
      const maxSizeInBytes = maxSizeInMB * 1024 * 1024;

      if (file && !validImageTypes.includes(file.type)) {
        error("Please upload a valid image file (jpg, jpeg, or png).");
        return;
      }

      if (file.size > maxSizeInBytes) {
        error(`File size must be less than ${maxSizeInMB}MB.`);
        return;
      }

      setUserProfileInfo((prev) => ({
        ...prev,
        [name]: file,
      }));
    } else {
      setUserProfileInfo((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handlePlaceSelected = () => {
    if (autocompleteRef.current) {
      const place = autocompleteRef.current.getPlace();
      setUserProfileInfo((prev) => ({
        ...prev,
        address: place.formatted_address || "",
      }));
    }
  };

  const handleDeleteImage = () => {
    setUserProfileInfo((prev) => ({ ...prev, image: "" }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const hasChanged = Object.keys(userProfileInfo).some(
      (key) => userProfileInfo[key] !== initialUserProfileInfo[key]
    );
    if (hasChanged) {
      let payload = new FormData();
      Object.entries(userProfileInfo).forEach(([key, val]) => {
        if (key === "image") {
          if (typeof val === "object") {
            payload.append(key, val);
          }
          return;
        } else {
          if (val) {
            payload.append(key, val);
          }
        }
      });
      dispatch(
        updateOnboardingUser({
          payload,
          onSuccess: (res) => {
            success(res);
            setActiveStep((prev) => prev + 1);
          },
        })
      );
    } else {
      setActiveStep((prev) => prev + 1);
    }
  };
  useEffect(() => {
    if (user) {
      const loadedUserData = {
        name: user?.fullName || "",
        email: user?.email || "",
        image: user?.image || "",
        address: user?.address || "",
        linkedIn_profile: user?.linkedIn_profile || "",
        contact: user?.contact || "",
        education: user?.education || "",
        employment_status: user?.employment_status || "",
      };
      setUserProfileInfo(loadedUserData);
      setInitialUserProfileInfo(loadedUserData);
    }
  }, [user]);
  return (
    <>
      {loading && <Loader />}
      <form onSubmit={handleSubmit}>
        <div className="profile-header">
          <div className="image-upload-container">
            {!userProfileInfo.image && (
              <>
                <input
                  accept=".png, .jpg, .jpeg"
                  style={{ display: "none" }}
                  id="image-upload-input"
                  type="file"
                  name="image"
                  onChange={handleProfileChange}
                />
                <label htmlFor="image-upload-input">
                  <div className="image-upload-box">
                    <span className="upload-icon text-center">
                      <MdFileUpload className="m-auto" />
                      <p>Upload you Photo</p>
                    </span>
                  </div>
                </label>
              </>
            )}

            {userProfileInfo.image && (
              <div className="image-preview-container">
                <img
                  src={
                    typeof userProfileInfo.image === "object"
                      ? URL.createObjectURL(userProfileInfo.image)
                      : userProfileInfo.image
                  }
                  alt="Selected"
                  className="image-preview"
                />
                <div className="image-overlay" onClick={handleDeleteImage}>
                  <button className="delete-icon">
                    <MdDelete />
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="profile-form_cover">
          <div className="profile-form">
            <input
              type="text"
              id="username"
              name="name"
              className="form-control mt-3"
              placeholder="Full Name"
              required
              value={userProfileInfo.name}
              onChange={handleProfileChange}
            />
            <input
              type="email"
              id="email"
              name="email"
              className="form-control mt-3"
              placeholder="Email"
              required
              disabled
              value={userProfileInfo.email}
              onChange={handleProfileChange}
            />
            <input
              type="text"
              id="contact"
              name="contact"
              className="form-control mt-3"
              placeholder="Contact Number"
              value={userProfileInfo.contact}
              onChange={handleProfileChange}
            />
            <input
              type="text"
              id="education"
              className="form-control mt-3"
              placeholder="Education"
              name="education"
              value={userProfileInfo.education}
              onChange={handleProfileChange}
            />
            {/* <input
              type="text"
              id="address"
              className="form-control mt-3"
              placeholder="Address"
              name="address"
              value={userProfileInfo.address}
              onChange={handleProfileChange}
            /> */}
            <Autocomplete
              onLoad={(autocomplete) =>
                (autocompleteRef.current = autocomplete)
              }
              onPlaceChanged={handlePlaceSelected}
              className="w-full"
            >
              <input
                type="text"
                id="address"
                className="form-control mt-3"
                placeholder="Address"
                name="address"
                value={userProfileInfo.address}
                onChange={handleProfileChange}
              />
            </Autocomplete>

            <input
              type="url"
              id="linkedIn_profile"
              className="form-control mt-3"
              placeholder="LinkedIn Profile URL"
              name="linkedIn_profile"
              value={userProfileInfo.linkedIn_profile}
              onChange={handleProfileChange}
            />
            <select
              id="employment_status"
              name="employment_status"
              value={userProfileInfo.employment_status}
              onChange={handleProfileChange}
              className="form-control mt-3"
            >
              <option value="" disabled>
                Select Employment Status
              </option>
              <option value="FullTime">Full-Time</option>
              <option value="PartTime">Part-Time</option>
              <option value="Contractor">Contractor</option>
              <option value="Intern">Intern</option>
              <option value="Freelancer">Freelancer</option>
              <option value="Temporary">Temporary</option>
              <option value="Unemployed">Unemployed</option>
              <option value="Student">Student</option>
              <option value="Retired">Retired</option>
              <option value="OnLeave">OnLeave</option>
              <option value="Sabbatical">Sabbatical</option>
              <option value="Probation">Probation</option>
            </select>

            <div
              className={`w-full flex ${
                activeStep === 1 ? "justify-end" : "justify-between"
              }`}
            >
              <button className="ic-btn" type="submit">
                Save & Next
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default UserInfo;
