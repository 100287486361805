import { Select } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCountryStates, getJobListings } from "../../Redux/NexaIqSlice";
import { useDebounce } from "use-debounce";
import { error } from "../../Utilities/toast";
import { Autocomplete } from "@react-google-maps/api";
const { Option } = Select;
const FilterComponent = ({
  storeActivePagination,
  activeTab,
  selectedCity,
  setSelectedCity,
  setPostedDate,
  postedDate,
  setJobSearch,
  jobSearch,
  // setSelectedState,
  // selectedState,
  workplaceType,
  setWorkplaceType,
  experienceLevel,
  setExperienceLevel,
  setLoading,
  filterNexaJobs,
  setFilterNexaJobs,
}) => {
  const dispatch = useDispatch();
  const { countryStates, stateCities } = useSelector((state) => state.nexaIq);
  const [allStateCities, setAllStateCities] = useState([]);
  const [clearFilterViewable, setClearFilterViewable] = useState(false);
  const [autocomplete, setAutocomplete] = useState(null);

  // const [selectedState, setSelectedState] = useState(null);
  // const [selectedCity, setSelectedCity] = useState(null);
  // const [postedDate, setPostedDate] = useState(null);
  // const [jobSearch, setJobSearch] = useState("");
  // console.log(jobSearch, "jobSearch");
  // const [debouncedSearch] = useDebounce(jobSearch, 500);
  // console.log(debouncedSearch, "jobdebouncedSearch");

  const clearFilter = () => {
    setFilterNexaJobs(null);
    setSelectedCity("");
    setAutocomplete(null);
    setPostedDate(null);
    setJobSearch("");
    setLoading(true);
    // setSelectedState(null);
    setWorkplaceType(null);
    setExperienceLevel(null);
    dispatch(
      getJobListings({
        payload: null,
        page: 1,
        jobApplied: activeTab === "Applied Jobs" ? true : false,
        smartScreen: activeTab === "Smart Screen Jobs" ? true : false,
        nexa_jobs: false,
        onSuccess: () => {
          setLoading(false);
          setClearFilterViewable(false);
        },
      })
    );
  };
  const handleSearch = () => {
    if (jobSearch == "") return;
    if (selectedCity == null) {
      error("Location is required for job search");
      return;
    }
    setLoading(true);
    const payload = {
      location: selectedCity,
      from_date: postedDate,
      title: jobSearch.toLowerCase(),
      workplace_type: workplaceType,
      experience_level: experienceLevel,
    };

    dispatch(
      getJobListings({
        payload,
        page: 1,
        jobApplied: activeTab === "Applied Jobs" ? true : false,
        smartScreen: activeTab === "Smart Screen Jobs" ? true : false,
        nexa_jobs: filterNexaJobs ? true : false,
        onSuccess: () => {
          setLoading(false);
        },
      })
    );
  };
  // useEffect(() => {
  //   dispatch(
  //     getCountryStates({
  //       state: selectedState,
  //       onSuccess: () => {},
  //     })
  //   );
  //   setSelectedCity(null);
  // }, [selectedState]);
  // useEffect(() => {
  //   if (stateCities && stateCities[0] !== "All") {
  //     setAllStateCities(["All", ...stateCities]);
  //   }
  // }, [stateCities]);
  useEffect(() => {
    console.log("selectedCity: ", selectedCity);
    if (selectedCity === null) {
      setAutocomplete(null);
    }
    if (
      selectedCity != null ||
      postedDate != null ||
      jobSearch != "" ||
      // selectedState != null ||
      workplaceType != null ||
      experienceLevel != null ||
      filterNexaJobs != null
    ) {
      setClearFilterViewable(true);
    } else {
      setClearFilterViewable(false);
    }
  }, [
    selectedCity,
    postedDate,
    jobSearch,
    // selectedState,
    workplaceType,
    experienceLevel,
    filterNexaJobs,
  ]);
  useEffect(() => {
    // let payload = {};
    // if (selectedCity) {
    //   payload = {
    //     ...payload,
    //     location: selectedCity,
    //   };
    //   console.log("Applying City Filter", payload);
    // }

    // if (postedDate) {
    //   payload = {
    //     ...payload,
    //     from_date: postedDate,
    //   };
    //   console.log("Applying Posted Date Filter", payload);
    // }

    // if (jobSearch) {
    //   payload = {
    //     ...payload,
    //     title: jobSearch.toLowerCase(),
    //   };
    //   console.log("Applying Debounce Search Filter", payload);
    // }
    // if (Object.keys(payload).length > 0) {
    //   dispatch(getJobListings({ payload, page: 1, onSuccess: () => {} }));
    // } else {
    if (
      selectedCity != null ||
      postedDate != null ||
      workplaceType != null ||
      experienceLevel != null
    ) {
      // setLoading(true);
      let payload = {
        from_date: postedDate,
        title: jobSearch.toLowerCase(),
        location: selectedCity,
        workplace_type: workplaceType,
        experience_level: experienceLevel,
      };
      dispatch(
        getJobListings({
          payload: payload,
          jobApplied: activeTab === "Applied Jobs" ? true : false,
          smartScreen: activeTab === "Smart Screen Jobs" ? true : false,
          page: 1,
          nexa_jobs: filterNexaJobs ? true : false,
          onSuccess: () => {
            // setLoading(false);
          },
        })
      );
    }
    // }
  }, [selectedCity, postedDate, workplaceType, experienceLevel]);

  const onLoad = (autocompleteInstance) => {
    setAutocomplete(autocompleteInstance);
  };
  const onPlaceChanged = () => {
    if (autocomplete) {
      const place = autocomplete.getPlace();
      const city = place.address_components.find((component) =>
        component.types.includes("locality")
      )?.long_name;
      setSelectedCity(city || place.formatted_address);
    }
  };
  return (
    <>
      <div
        className={`fillter ${
          activeTab != "Applied Jobs" ? "h-[150px]" : "d-none"
        } `}
      ></div>
      <div className={`wrappertop p-0`}>
        {activeTab != "Applied Jobs" && (
          <div className="filter-component bg-transparent hide-mob">
            <div className="flex flex-wrap h-full w-full items-center justify-center space-x-1 custom-max-width">
              {/* <div className="w-[200px] mt-1">
                <Select
                  placeholder="Select State"
                  value={selectedState}
                  onChange={(value) => setSelectedState(value)}
                  className="w-full mt-1 h-[48px]"
                  optionFilterProp="children"
                  showSearch
                >
                  {countryStates?.map((option) => (
                    <Option key={option.name} value={option.name}>
                      {option.name}
                    </Option>
                  ))}
                </Select>
              </div> */}
              <div className="mt-1">
                {/* <Select
                  placeholder="Select City"
                  value={selectedCity}
                  // disabled={!selectedState}
                  onChange={(value) => setSelectedCity(value)}
                  className="w-full mt-1 h-[48px]"
                  // title={!selectedState ? "Please select state first" : ""}
                  showSearch
                >
                  {allStateCities?.map((option) => (
                    <Option key={option} value={option}>
                      {option}
                    </Option>
                  ))}
                </Select> */}
                <Autocomplete
                  onLoad={onLoad}
                  onPlaceChanged={onPlaceChanged}
                  options={{
                    types: ["(cities)"], // Restrict to cities
                    componentRestrictions: { country: ["us", "gb", "ca"] }, // Restrict to USA, UK, Canada
                  }}
                >
                  <input
                    className="w-[300px] mt-1 h-[48px] p-2 border rounded"
                    type="text"
                    placeholder="Enter a city"
                    value={selectedCity}
                    onChange={(e) => setSelectedCity(e.target.value)}
                  />
                </Autocomplete>
              </div>

              <div className="w-[200px] mt-1">
                <Select
                  placeholder="Date posted"
                  value={postedDate}
                  onChange={(value) => setPostedDate(value)}
                  className="w-full mt-1 h-[48px]"
                  showSearch
                >
                  <Option value="any_time">Any Time</Option>
                  <Option value="past_month">Past Month</Option>
                  <Option value="past_week">Past Week</Option>
                  <Option value="past_3_days">Past 3 days</Option>
                  <Option value="past_24_hours">Past 24 hours</Option>
                </Select>
              </div>
              <div className="w-[200px] mt-1">
                <Select
                  placeholder="Workplace Type"
                  value={workplaceType}
                  onChange={(value) => setWorkplaceType(value)}
                  className="w-full mt-1 h-[48px]"
                  showSearch
                >
                  <Option value="remote">Remote</Option>
                  <Option value="hybird">Hybird</Option>
                  <Option value="onsite">onsite</Option>
                </Select>
              </div>
              <div className="w-[200px] mt-1">
                <Select
                  placeholder="Experience Level"
                  value={experienceLevel}
                  onChange={(value) => setExperienceLevel(value)}
                  className="w-full mt-1 h-[48px]"
                  showSearch
                >
                  <Option value="beginner">Beginner</Option>
                  <Option value="intermediate">Intermediate</Option>
                  <Option value="expert">Expert</Option>
                </Select>
              </div>
              <div className="shrink-1 mt-1">
                <div className="relative flex justify-center item-center">
                  <input
                    type="text"
                    id="resumeName"
                    className="form-control "
                    placeholder="Search via job title"
                    value={jobSearch}
                    onChange={(e) => setJobSearch(e.target.value)}
                  />
                  <span
                    className="absolute inset-y-0 right-3 flex items-center cursor-pointer"
                    onClick={handleSearch}
                  >
                    <svg
                      class="w-4 h-4 text-gray-500"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 20 20"
                    >
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                      />
                    </svg>
                  </span>
                  {/* <div className="py-[10px] px-[16px] cursor-default rounded-[8px] flex items-center border border-black bg-[#FAFFEB]">
                  <span className="text-sm font-semibold whitespace-nowrap max-w-[150px] text-ellipsis overflow-hidden">
                    Internship
                  </span>
                  <svg
                    width="17"
                    height="16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="ml-1"
                  >
                    <path
                      d="m4.5 5.334-.715.781L8.5 10.83l4.714-4.714-.714-.781-4 4-4-4Z"
                      fill="#4D4D4D"
                    ></path>
                  </svg>
                </div> */}
                </div>
              </div>
            </div>
            <div className="flex items-center justify-center">
              {clearFilterViewable && (
                <div className="shrink-1">
                  <button
                    className="bg-[#FF0000] text-white rounded-lg py-2 mt-1 px-6 border-none text-sm flex items-center gap-2 cursor-pointer"
                    onClick={() => clearFilter()}
                  >
                    Clear Filter
                  </button>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default FilterComponent;
