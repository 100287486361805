import React, { useEffect, useState } from "react";
import "./style.css";
import "./TextEditor.css";
import Header from "../../Components/header";
import MyDetails from "../../features/profile/profileForm";
import { useDispatch, useSelector } from "react-redux";
import ProfessionalSummary from "../../features/professionalsummary/professionalSummaryForm";
import CVTemplate from "../../Components/Resume/CVTemplate";
import ResumeHeader from "../../Components/Resume/ResumeHeader";
import WorkExperienceForm from "../../features/workexperience/workExperienceForm";
import EducationForm from "../../features/education/EducationForm";
import Skills from "../../features/Skills/Skills";
import CompanyInfoForm from "../../features/companyinformation/CompanyInfoForm";
import {
  addStep,
  selectStep,
  setStep1,
  setActivePreview,
} from "../../features/companyinformation/StepsSlice";
import Guidance from "../../Components/Resume/Guidance";
import ProjectForm from "../../features/projects/ProjectForm";
import CertificationForm from "../../features/Certifications/CertificationForm";
import PublicationForm from "../../features/publications/PublicationForm";
import AiGeneration from "../../Components/Resume/AiGeneration";
import {
  addAdditionalSections,
  deleteResumePosition,
  getSingleResume,
  updateResumePosition,
} from "../../Redux/ResumeBuilderSlice";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../Components/main-loader";
import InterestForm from "../../features/Interests/InterestForm";
import LanguagesForm from "../../features/Languages/LanguagesForm";
import SoftSkills from "../../features/companyinformation/SoftSkills/SoftSkills";
import Preview from "../../Components/Resume/Preview";
import chevronDoubleRightIcon from "../../assets/icons/chevronDoubleRight.svg";
import {
  ProfessionalSummaryIcon,
  EducationIcon,
  WorkexperienceIcon,
  TopSkillsIcon,
  CertificateIcon,
  PersonalSumIcon,
  SectionsIcon,
} from "../../Components/Resume/svgimages";
import ReactModal from "react-modal";
import DatePicker from "../../Components/Resume/DatePicker";
import { error } from "../../Utilities/toast";
import AdditionalSections from "../../features/additionalSections/AdditionalSections";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const backgroundStyle = {
  backgroundImage: 'url("/images/background.svg")',
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  width: "100%",
  height: "auto",
};
const ResumeBuilder = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const {
    isLoading,
    profile,
    professionalSummaries,
    educations,
    workExperience,
    getSingleResumeDoc,
  } = useSelector((state) => state.ResumeBuilder);
  const step = useSelector(selectStep);
  const activeField = useSelector((state) => state?.educationForm?.activeField);
  const { activePreview } = useSelector((state) => state?.Steps);
  const [collapse, setCollapse] = useState("");
  const [tempRef, settempRef] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [removeSection, setRemoveSection] = useState(false);
  const [sectionsConfig, setSectionConfigs] = useState([]);
  console.log("sectionsConfig: ", sectionsConfig);
  const [additionalSectionModal, setAdditionalSectionModal] = useState(false);
  const [selectedPosition, setSelectedPosition] = useState("");
  const initialState = {
    title: "",
    heading: "",
    description: "",
    date: "",
  };
  const [additionalSection, setAdditionalSection] = useState(initialState);
  console.log("additionalSection: ", additionalSection);
  const toggleCollapse = (value) => {
    if (value !== collapse) {
      setCollapse(value);
    } else {
      setCollapse("");
    }
  };

  const handlePreview2 = () => {
    const payload = {
      preview: false,
      step: step,
      step1: true,
    };
    dispatch(setActivePreview(payload));
  };
  const handleOnDragEnd = (result) => {
    const { destination, source } = result;
    if (!destination) return;
    if (destination.index === source.index) return;
    const reorderedSections = Array.from(sectionsConfig);
    const [removed] = reorderedSections.splice(source.index, 1);
    reorderedSections.splice(destination.index, 0, removed);
    const previousSections = [...sectionsConfig];
    const updatedSections = reorderedSections.map((section, index) => ({
      ...section,
      position: index + 1,
    }));
    setSectionConfigs(updatedSections);
    dispatch(
      updateResumePosition({
        resumeId: id,
        payload: updatedSections,
        onSuccess: () => {
          dispatch(getSingleResume({ resumeId: id, onSuccess: () => {} }));
        },
        onError: () => {
          setSectionConfigs(previousSections);
        },
      })
    );
  };
  const checkDisable = () => {
    if (
      !(
        profile &&
        professionalSummaries?.length > 0 &&
        workExperience?.length > 0 &&
        educations?.length > 0
      )
    ) {
      return true;
    } else {
      return false;
    }
  };
  useEffect(() => {
    let sectionComponents = [
      {
        key: "details",
        icon: <PersonalSumIcon />,
        component: <MyDetails />,
      },
      {
        key: "professionalsummary",
        icon: <ProfessionalSummaryIcon />,
        component: <ProfessionalSummary />,
      },
      {
        key: "certification",
        icon: <CertificateIcon />,
        component: <CertificationForm />,
      },
      {
        key: "skills",
        icon: <TopSkillsIcon />,
        component: <Skills />,
      },
      {
        key: "workexperience",
        icon: <WorkexperienceIcon />,
        component: <WorkExperienceForm />,
      },
      {
        key: "education",
        icon: <EducationIcon />,
        component: <EducationForm />,
      },
      {
        key: "project",
        icon: "",
        component: <ProjectForm />,
      },
      {
        key: "publications",
        icon: "",
        component: <PublicationForm />,
      },
      {
        key: "interests",
        icon: "",
        component: <InterestForm />,
      },
      {
        key: "skill",
        icon: "",
        component: <SoftSkills />,
      },
      {
        key: "language",
        icon: "",
        component: <LanguagesForm />,
      },
    ];
    const updatedSections = getSingleResumeDoc?.positions
      .filter((position) => position.isDeleted === false)
      .map((position) => {
        if (!position.additional) {
          const matchingComponent = sectionComponents.find(
            (component) => component.key === position.key
          );
          if (matchingComponent) {
            return {
              ...position,
              icon: matchingComponent.icon,
              component: matchingComponent.component,
            };
          }
        } else {
          const matchingAdditional =
            getSingleResumeDoc?.additional_sections?.find(
              (additionalItem) => additionalItem.heading === position.title
            );
          if (matchingAdditional) {
            return {
              ...position,
              icon: <SectionsIcon />,
              component: (
                <AdditionalSections
                  section={matchingAdditional}
                  resumeId={id}
                />
              ),
            };
          }
        }
        return position;
      });
    const sortedSections = updatedSections?.sort(
      (a, b) => a.position - b.position
    );
    setSectionConfigs(sortedSections);
  }, [getSingleResumeDoc]);
  useEffect(() => {
    if (id) {
      const resumeId = id;
      dispatch(getSingleResume({ resumeId, onSuccess: () => {} }));
    }
  }, [id]);
  useEffect(() => {
    toggleCollapse("contactInfoShow");
  }, []);
  return (
    <div style={backgroundStyle}>
      <Header />
      {isLoading && <Loader />}
      <div className="resume-back">
        <button
          onClick={() => {
            if (step > 2) {
              navigation("/resumes");
            } else {
              setModalIsOpen(true);
            }
          }}
          className={` z-50 mt-28 ml-2 bg-white rounded-full size-10 p-1 transition-all duration-500  rotate-180`}
        >
          <img src={chevronDoubleRightIcon} alt="" />
        </button>
      </div>
      <ResumeHeader
        resumeId={id}
        targetId="pdf-generate"
        handlePreview2={handlePreview2}
      />

      <div className="resume">
        {step === 1 && !activePreview && (
          <>
            <div className="resume-work">
              {/* <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable droppableId="sections" direction="vertical">
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      className="w-full"
                    > */}
              {sectionsConfig?.map((section, index) => (
                // <Draggable
                //   key={section.key}
                //   draggableId={section.key}
                //   index={index}
                // >
                //   {(provided) => (
                <div
                  // ref={provided.innerRef}
                  // {...provided.draggableProps}
                  // {...provided.dragHandleProps}
                  className={section.className}
                  style={{
                    // ...provided.draggableProps.style,
                    border:
                      collapse === section.key ? "1px solid #2A68DC" : "none",
                    borderRadius: "8px",
                    marginBottom: "10px",
                    width: "100%",
                  }}
                >
                  <div
                    className="relative d-flex justify-content-between py-1 px-2"
                    style={{ backgroundColor: "white" }}
                  >
                    {/* {!section.isRequired && section.isRequired != null && (
                    <div
                      className="absolute -top-2 -right-2 bg-white text-red-600 rounded-full w-6 h-6 flex items-center justify-center shadow cursor-pointer"
                      onClick={() => {
                        setRemoveSection(true);
                        setSelectedPosition(section?.key);
                      }}
                    >
                      <i className="ri-close-line text-sm"></i>
                    </div>
                  )} */}
                    <div className="d-flex">
                      <div
                        style={{
                          marginTop: "5px",
                          marginRight: "10px",
                        }}
                      >
                        {section.icon}
                      </div>
                      <h3>{section.title}</h3>
                      {section.isRequired && section.isRequired != null && (
                        <span className="text-red-600 mt-1">*</span>
                      )}
                    </div>
                    <button
                      className="toggle_button text-center d-flex align-items-center justify-content-center"
                      onClick={() => toggleCollapse(section.key)}
                    >
                      <i
                        className={`ri-arrow-${
                          collapse === section.key ? "up" : "down"
                        }-s-line`}
                        style={{
                          fontSize: "26px",
                          color:
                            collapse === section.key ? "#2A68DC" : "inherit",
                        }}
                      />
                    </button>
                  </div>
                  {collapse === section.key && section.component}
                </div>
                //   )}
                // </Draggable>
              ))}
              {/* {provided.placeholder} */}
              {/* </div>
                  )}
                </Droppable>
              </DragDropContext> */}
              <div className="d-flex justify-content-between  py-1 px-4 mt-1">
                <button
                  className="professionalitem-button"
                  onClick={() => {
                    setAdditionalSection(initialState);
                    setAdditionalSectionModal(true);
                  }}
                >
                  <i className="ri-add-circle-line"></i>
                  <span className="ml-2">Additional Sections</span>
                </button>
                <button
                  className={` ${
                    checkDisable()
                      ? "cursor-not-allowed save-btn-disabled"
                      : "save-btn"
                  }`}
                  style={{
                    fontWeight: "500",
                    width: "130px",
                    height: "50px",
                  }}
                  disabled={checkDisable()}
                  onClick={() => {
                    window.scrollTo({ top: 0, behavior: "smooth" });
                    dispatch(addStep(2));
                    dispatch(setStep1(true));
                  }}
                >
                  <span
                    style={{
                      fontSize: "20px",
                      fontWeight: "500",
                    }}
                  >
                    Next
                  </span>
                  <i
                    className="ri-arrow-right-line ml-2"
                    style={{
                      fontSize: "20px",
                      fontWeight: "500",
                    }}
                  />
                </button>
              </div>
            </div>

            {!activeField && (
              <div id="pdf-generate">
                <div className="cv-main-container w-[700px]">
                  <CVTemplate tempRef={tempRef} settempRef={settempRef} />
                </div>
              </div>
            )}

            {activeField && step === 1 && <Guidance />}
          </>
        )}

        {step === 2 && !activePreview && (
          <>
            <div className="companyinformation">
              <CompanyInfoForm />
            </div>
            <div id="pdf-generate">
              <div className="cv-main-container w-[760px]">
                <CVTemplate tempRef={tempRef} blured={true} />
              </div>
            </div>
          </>
        )}

        {step >= 3 && !activePreview && (
          <AiGeneration tempRef={tempRef} settempRef={settempRef} />
        )}
        {activePreview && <Preview />}
      </div>
      <ReactModal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            padding: "20px",
            maxWidth: "500px",
            width: "90%",
            borderRadius: "10px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
          },
        }}
      >
        <h2>Confirmation Box</h2>
        <hr />
        <div className="modal-body">
          <div className="">
            <p className="text-center">
              You are about to exit the resume module, please make sure your
              work is saved before exiting back to home
            </p>
            <div className="flex align-items-center justify-content-center mt-2">
              <button
                className="bg-green-600 px-4 py-2 rounded text-white"
                onClick={() => setModalIsOpen(false)}
              >
                Cancel
              </button>
              <button
                className="bg-red-600 px-4 py-2 rounded mx-2 text-white"
                onClick={() => navigation("/resumes")}
              >
                Exit
              </button>
            </div>
          </div>
        </div>
      </ReactModal>
      <ReactModal
        isOpen={removeSection}
        onRequestClose={() => setRemoveSection(false)}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            padding: "20px",
            maxWidth: "500px",
            width: "90%",
            borderRadius: "10px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
          },
        }}
      >
        <h2>Confirmation Box</h2>
        <hr />
        <div className="modal-body">
          <div className="">
            <p className="text-center">
              Are you sure you want to remove this section permanentely?
            </p>
            <div className="flex align-items-center justify-content-center mt-2">
              <button
                className="bg-red-600 px-4 py-2 rounded text-white"
                onClick={() => setRemoveSection(false)}
              >
                No
              </button>
              <button
                className=" bg-green-600 px-4 py-2 rounded mx-2 text-white"
                onClick={() => {
                  dispatch(
                    deleteResumePosition({
                      resumeId: id,
                      heading: selectedPosition,
                      onSuccess: () => {
                        setRemoveSection(false);
                        dispatch(
                          getSingleResume({ resumeId: id, onSuccess: () => {} })
                        );
                      },
                      onError: () => {},
                    })
                  );
                }}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </ReactModal>
      <ReactModal
        isOpen={additionalSectionModal}
        onRequestClose={() => setAdditionalSectionModal(false)}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            padding: "20px",
            maxWidth: "500px",
            width: "90%",
            borderRadius: "10px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
          },
        }}
      >
        <h2>Additional Section</h2>
        <hr />
        <div className="mt-3">
          <div className="form-group mr-2 w-full ">
            <input
              type="text"
              id="location"
              className="form-control"
              placeholder="Section Title"
              value={additionalSection?.heading}
              onChange={(e) =>
                setAdditionalSection({
                  ...additionalSection,
                  heading: e.target.value,
                })
              }
            />
          </div>
          <div className="d-flex  w-full flex-col md:flex-row">
            <div className="form-group mr-2 w-full md:w-50">
              <input
                type="text"
                id="location"
                className={`form-control ${
                  additionalSection?.title !== "" ? "shadow-lg" : ""
                }`}
                placeholder="Title"
                value={additionalSection?.title}
                onChange={(e) =>
                  setAdditionalSection({
                    ...additionalSection,
                    title: e.target.value,
                  })
                }
              />
            </div>
            <div
              className={`form-group ml-0 md:ml-3 w-full md:w-50 ${
                additionalSection?.date !== "" ? "shadow-lg" : ""
              } rounded-xl`}
            >
              <DatePicker
                selectedDate={additionalSection?.date}
                onSelect={(e) =>
                  setAdditionalSection({ ...additionalSection, date: e })
                }
              />
            </div>
          </div>
          <div className="mr-2 w-full">
            <textarea
              row="4"
              id="location"
              placeholder="Description"
              className={`border border-1 p-2 ${
                additionalSection?.description !== "" ? "shadow-lg " : ""
              } `}
              value={additionalSection?.description}
              onChange={(e) =>
                setAdditionalSection({
                  ...additionalSection,
                  description: e.target.value,
                })
              }
            />
          </div>
          <button
            className="bg-blue-500 px-4 py-2 rounded mx-2 text-white"
            onClick={() => {
              if (additionalSection?.heading === "") {
                return error("Section heading is required");
              }
              const payload = {
                ...(additionalSection?.heading && {
                  heading: additionalSection?.heading,
                }),
                ...(additionalSection?.title && {
                  title: additionalSection?.title,
                }),
                ...(additionalSection?.date && {
                  date: new Date(additionalSection?.date).toLocaleDateString(
                    "en-CA"
                  ),
                }),
                ...(additionalSection?.description && {
                  description: additionalSection?.description,
                }),
              };
              dispatch(
                addAdditionalSections({
                  payload,
                  resumeId: id,
                  onSuccess: () => {
                    setAdditionalSectionModal(false);
                    setAdditionalSection(initialState);
                    dispatch(
                      getSingleResume({ resumeId: id, onSuccess: () => {} })
                    );
                  },
                  onError: () => {},
                })
              );
            }}
          >
            Save Changes
          </button>
        </div>
      </ReactModal>
    </div>
  );
};

export default ResumeBuilder;
