import React, { useEffect, useState } from "react";
import { getCurrentUser } from "../../../Redux/authenticate/authenticate.action";
import { useDispatch, useSelector } from "react-redux";
import {
  AddCertifications,
  deleteCertification,
  deleteCertificationFile,
  uploadCertiticateFiles,
} from "../../../Redux/UserProfileSlice";
import { error, success } from "../../../Utilities/toast";
import { IoMdRemoveCircle } from "react-icons/io";

const CertificationsLicenses = ({ activeStep, setActiveStep }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.authenticate);
  const [initialUserProfileInfo, setInitialUserProfileInfo] = useState([
    { name: "", date: "", file_path: null, id: null },
  ]);
  const [certifications, setCertifications] = useState([
    { name: "", date: "", file_path: null, id: null },
  ]);
  console.log(certifications, "certifications");
  console.log(initialUserProfileInfo, "certificationsProfileInfo");
  const handleChange = (index, event) => {
    const values = certifications?.map((cert, i) =>
      i === index ? { ...cert, [event.target.name]: event.target.value } : cert
    );
    setCertifications(values);
  };

  const handleFileChange = (index, event) => {
    dispatch(
      uploadCertiticateFiles({
        payload: event.target.files[0],
        onSuccess: (res) => {
          success("File uploaded successfully");
          const values = certifications?.map((cert, i) =>
            i === index ? { ...cert, file_path: res } : cert
          );
          setCertifications(values);
        },
      })
    );
  };

  const handleAddCertification = () => {
    const lastCertification = certifications[certifications.length - 1];
    const isLastCertificationValid =
      lastCertification &&
      lastCertification.name &&
      lastCertification.date &&
      lastCertification.file_path;
    if (isLastCertificationValid) {
      setCertifications([
        ...certifications,
        { name: "", date: "", file_path: null, id: null },
      ]);
    } else {
      error("Please fill in the current certificate before adding a new one.");
    }
  };
  const handleRemoveCertification = (index, obj) => {
    const selectedData = certifications.find((item) => item == obj);
    if (selectedData?.id) {
      dispatch(
        deleteCertification({
          id: selectedData?.id,
          onSuccess: () => {
            success("Certificate deleted successfully");
            dispatch(getCurrentUser());
          },
        })
      );
    } else {
      const newCertificates = [...certifications];
      newCertificates.splice(index, 1);
      setCertifications(newCertificates);
    }
  };

  const handleSave = () => {
    const hasChanged =
      JSON.stringify(certifications) !== JSON.stringify(initialUserProfileInfo);
    if (hasChanged) {
      if (
        certifications.some(
          (certificate) =>
            !certificate.name || !certificate.date || !certificate.file_path
        )
      ) {
        return error("Please fill in all the required fields");
      }

      if (
        certifications.some(
          (certificate) =>
            certificate.file_path instanceof File &&
            certificate.file_path.size > 1 * 1024 * 1024
        )
      ) {
        return error("Please select a less than 1MB");
      }

      dispatch(
        AddCertifications({
          certifications,
          onSuccess: () => {
            success("Certifications added successfully");
            dispatch(getCurrentUser());
            setActiveStep((prev) => prev + 1);
          },
        })
      );
    } else {
      setActiveStep((prev) => prev + 1);
    }
  };

  useEffect(() => {
    if (user && user.certifications && user.certifications.length > 0) {
      const deepCopy = user.certifications?.map((cert) => ({ ...cert }));
      console.log(deepCopy, "deepCopy");
      setCertifications(deepCopy);
      setInitialUserProfileInfo(deepCopy);
    } else {
      setCertifications([{ name: "", date: "", file_path: null, id: null }]);
      setInitialUserProfileInfo([
        { name: "", date: "", file_path: null, id: null },
      ]);
    }
  }, [user]);

  return (
    <div className="p-4">
      {certifications?.map((certification, index) => (
        <div key={index} className="mb-4 p-4 border rounded-lg shadow">
          <div className="mb-2">
            <label className="block mb-1">Name:</label>
            <input
              type="text"
              name="name"
              value={certification.name}
              onChange={(event) => handleChange(index, event)}
              className="border rounded p-2 w-full"
              placeholder="Enter certification name"
              required
            />
          </div>
          <div className="mb-2">
            <label className="block mb-1">Date of Issuance:</label>
            <input
              type="date"
              name="date"
              value={certification.date}
              onChange={(event) => handleChange(index, event)}
              className="border rounded p-2 w-full"
              required
            />
          </div>
          <div className="mb-2">
            <label className="block mb-1">Upload Document:</label>
            {certification?.file_path && (
              <div className="User_profile-pic">
                <img
                  src="https://www.iconpacks.net/icons/2/free-file-icon-1453-thumb.png"
                  height={70}
                  width={70}
                />
                <button
                  onClick={() => {
                    dispatch(
                      deleteCertificationFile({
                        file: certification?.file_path,
                        onSuccess: (val) => {
                          success("Certificate File deleted successfully");
                          const values = certifications?.map((cert, i) =>
                            i === index ? { ...cert, file_path: val } : cert
                          );
                          setCertifications(values);
                        },
                      })
                    );
                  }}
                >
                  <div className="User_file-edit">
                    <IoMdRemoveCircle size={23} color="black" />
                  </div>
                </button>
              </div>
            )}
            {!certification?.file_path && (
              <input
                type="file"
                name="file_path"
                onChange={(event) => handleFileChange(index, event)}
                className="border rounded p-2 w-full"
                accept="*"
              />
            )}
          </div>
          {certifications?.length > 1 && (
            <button
              type="button"
              onClick={() => handleRemoveCertification(index, certification)}
              className="text-white bg-red-600 mt-3 px-4 py-2 rounded-lg"
            >
              Remove Certification
            </button>
          )}
        </div>
      ))}
      <div className="w-full flex gap-2 justify-end">
        <button
          type="button"
          onClick={handleAddCertification}
          className="mt-2 bg-blue-500 text-white p-2 rounded"
        >
          Add Another Certification
        </button>
      </div>
      <div className={`w-full flex mt-6 justify-between`}>
        <button
          className="ic-btn"
          onClick={() => {
            setActiveStep((prev) => prev - 1);
          }}
        >
          Previous
        </button>
        <button className="ic-btn" onClick={handleSave}>
          Save & Next
        </button>
      </div>
    </div>
  );
};

export default CertificationsLicenses;
