import React, { useState } from "react";
import DatePicker from "../../Components/Resume/DatePicker";
import { useDispatch } from "react-redux";
import {
  getSingleResume,
  updateAdditionalSections,
} from "../../Redux/ResumeBuilderSlice";

const AdditionalSections = ({ section, resumeId }) => {
  const dispatch = useDispatch();
  const [sectionData, setSectionData] = useState(section);

  const handleChange = (key, value) => {
    setSectionData((prev) => ({ ...prev, [key]: value }));
  };

  return (
    <div className="bg-white px-2 rounded-md mt-2 p-4 max-h-96 overflow-y-auto">
      {sectionData?.title !== null && (
        <div className="mb-2">
          <label className="block text-sm font-medium text-gray-700">
            Title
          </label>
          <input
            type="text"
            value={sectionData.title || ""}
            onChange={(e) => handleChange("title", e.target.value)}
            className="w-full p-2 border rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
          />
        </div>
      )}
      {sectionData?.description !== null && (
        <div className="mb-2">
          <label className="block text-sm font-medium text-gray-700">
            Description
          </label>
          <textarea
            value={sectionData.description || ""}
            onChange={(e) => handleChange("description", e.target.value)}
            className="w-full p-2 border rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
          />
        </div>
      )}
      {sectionData?.date !== null && (
        <div className="mb-2">
          <label className="block text-sm font-medium text-gray-700">
            Date
          </label>
          <DatePicker
            selectedDate={sectionData.date ? new Date(sectionData.date) : null}
            onSelect={(date) => handleChange("date", date)}
            className="w-full p-2 border rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
            popperClassName="z-50" // Ensures the DatePicker is above other elements
          />
        </div>
      )}
      <div className="flex justify-end">
        <button
          className="bg-blue-500 px-4 py-2 rounded mx-2 text-white"
          onClick={() => {
            const payload = {
              ...(sectionData?.title && {
                title: sectionData?.title,
              }),
              ...(sectionData?.date &&
                (sectionData?.date !== section?.date
                  ? {
                      date: new Date(sectionData?.date).toLocaleDateString(
                        "en-CA"
                      ),
                    }
                  : { date: sectionData?.date })),

              ...(sectionData?.description && {
                description: sectionData?.description,
              }),
            };
            dispatch(
              updateAdditionalSections({
                payload,
                sectionId: section?.id,
                onSuccess: () => {
                  dispatch(
                    getSingleResume({ resumeId: resumeId, onSuccess: () => {} })
                  );
                },
                onError: () => {},
              })
            );
          }}
        >
          Update
        </button>
      </div>
    </div>
  );
};

export default AdditionalSections;
