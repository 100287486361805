import React, { useEffect, useState } from "react";
import Header from "../../Components/header";
import Loader from "../../Components/main-loader";
import { useDispatch, useSelector } from "react-redux";
import UserInfo from "./components/UserInfo";
import Progress from "../../Components/Progress";
import KeySkillsProficiencyLevels from "./components/KeySkillsProficiencyLevels";
import CertificationsLicenses from "./components/CertificationsLicenses";
import WorkSamplesLinks from "./components/WorkSamplesLinks";
import ExpEduHistory from "./components/ExpEduHistory";
import JobPreference from "./components/JobPreference";
import ShowcaseTalent from "./components/ShowcaseTalent";
import {
  getCompletedInterviewSessions,
  getCompletedSimulatorCount,
  getJobRoles,
  getkeySkills,
} from "../../Redux/UserProfileSlice";
import CongratulationsPopup from "./components/CongratulationsPopup";

const UserProfile = () => {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.UserProfile);
  const { user } = useSelector((state) => state.authenticate);
  const [userProfileInfo, setUserProfileInfo] = useState({
    image: null,
    name: "",
    email: "",
    address: "",
    education: "",
    contact: "",
    employment_status: "",
    linkedIn_profile: "",
  });
  const [activeStep, setActiveStep] = useState(1);
  const [isVisible, setIsVisible] = useState(false);

  const calculateActiveStep = (percentage) => {
    if (percentage >= 0 && percentage < 20) return 1;
    if (percentage >= 20 && percentage < 40) return 2;
    if (percentage >= 40 && percentage < 60) return 3;
    if (percentage >= 60 && percentage < 80) return 4;
    if (percentage >= 80 && percentage < 90) return 5;
    if (percentage >= 90 && percentage < 100) return 6;
    if (percentage === 100) return 1;
    return 1;
  };

  useEffect(() => {
    if (user.is_badge_first_time) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  }, [user]);

  useEffect(() => {
    dispatch(getCompletedSimulatorCount({ onSuccess: () => {} }));
    dispatch(getCompletedInterviewSessions({ onSuccess: () => {} }));
    dispatch(getJobRoles({ onSuccess: () => {} }));
    dispatch(getkeySkills({ education: user?.education, onSuccess: () => {} }));
    if (user?.profile_completion) {
      setActiveStep(calculateActiveStep(user.profile_completion));
    }
  }, []);
  return (
    <>
      {loading && <Loader />}
      <Header />
      <div
        className="ic-interview-full-body"
        style={{
          backgroundImage: `url(${"/images/interview-banner.png"})`,
          paddingTop: "6em",
        }}
      >
        <main
          role="main"
          className="ml-sm-auto col-lg-12 px-4 bg-cover"
          style={{
            backgroundImage: `url(${"/images/interview-banner.png"})`,
          }}
        >
          <div className="pt-3 pb-2 mb-3 text-left flex flex-col 2xl:justify-center 2xl:items-center w-full 2xl:text-center">
            <h1 className="h2 2xl:text-center">
              Showcase Your Skills To Directly Be Seen By Hiring Managers
            </h1>
            <p className="2xl:max-w-[1000px] 2xl:text-center">
              Welcome to the NEXA talent hub, here you can showcase all your
              skills that make you the perfect candidate. This is your time,
              brag a bit! We will take all your amazing work & package it to our
              employer networks & if you match a role they are looking for you
              will show up directly in-front of them
            </p>
          </div>
          <div className="career_slide-cover">
            <div className="ic-card_container">
              <h2 className="text-start text-3xl mb-6">
                <h1 className="h2">
                  {activeStep === 1
                    ? "Personal Information"
                    : activeStep === 2
                    ? "Key Skills & Proficiency Levels"
                    : activeStep === 3
                    ? "Certifications & Licenses"
                    : activeStep === 4
                    ? "Work Samples & Project Links"
                    : activeStep === 5
                    ? "Experience & Education History"
                    : activeStep === 6
                    ? "Showcase Your Talent"
                    : "Preset and Custom Categories for Job Preference"}
                </h1>
              </h2>
              <div className="w-full flex justify-center items-center mb-4">
                <Progress percentage={`${user?.profile_completion}%`} />
              </div>
              {activeStep === 1 && (
                <UserInfo
                  userProfileInfo={userProfileInfo}
                  setUserProfileInfo={setUserProfileInfo}
                  activeStep={activeStep}
                  setActiveStep={setActiveStep}
                />
              )}
              {activeStep === 2 && (
                <KeySkillsProficiencyLevels
                  activeStep={activeStep}
                  setActiveStep={setActiveStep}
                />
              )}

              {activeStep === 3 && (
                <CertificationsLicenses
                  activeStep={activeStep}
                  setActiveStep={setActiveStep}
                />
              )}

              {activeStep === 4 && (
                <WorkSamplesLinks
                  activeStep={activeStep}
                  setActiveStep={setActiveStep}
                />
              )}

              {activeStep === 5 && (
                <ExpEduHistory
                  activeStep={activeStep}
                  setActiveStep={setActiveStep}
                />
              )}
              {activeStep === 6 && (
                <ShowcaseTalent
                  activeStep={activeStep}
                  setActiveStep={setActiveStep}
                />
              )}
              {activeStep === 7 && (
                <JobPreference
                  activeStep={activeStep}
                  setActiveStep={setActiveStep}
                />
              )}
            </div>
          </div>
        </main>
      </div>
      <CongratulationsPopup isVisible={isVisible} setIsVisible={setIsVisible} />
    </>
  );
};

export default UserProfile;
